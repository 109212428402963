<template>
  <div class="content-wrapper overflow-visible">
    
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <div class="page-title pt-0 pb-3">
        <a href="javascript:;" @click="$router.back()" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </a>
      </div>
      <div class="row g-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-white">
              <h6 class="card-title font-weight-semibold">Pasien Operasi</h6>
            </div>
            <table class="table table-bordered table-sm patient-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Tindakan Operasi</th>
                  <th>Tindakan Anestesi</th>
                  <th>Diagnosa</th>
                  <th>Tanggal Operasi</th>
                  <th>Dokter Operator</th>
                  <th>Dokter Anestesi</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (dataOperasi||[])" :key="k">
                  <td>
                    <span>{{k+1}}</span>
                  </td>
                  <td>{{v.arano_tindakan_operasi||"-"}}
                  </td>
                  <td>{{v.arano_jenis_new||v.arano_jenis||"-"}}
                  </td>
                  <td>{{v.arano_diagnosa||"-"}}
                  </td>
                  <td>
                    {{v.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}} {{v.arano_jam_operasi_start}} s/d
                    {{v.arano_tanggal_operasi_end | moment("DD MMMM YYYY")}} {{v.arano_jam_operasi_end}}
                  </td>
                  <td>
                    <span v-if="!(v.dokter_operator||[]).length"> - </span>
                    <ul v-else>
                      <li v-for="(vk,kk) in (v.dokter_operator||[])" :key="kk+'s'">{{vk}}</li>
                    </ul>
                  </td>
                  <td>
                    {{v.dokter_anestesi||"-"}}
                  </td>
                  <td>
                    <a href="javascript:;" @click="openFormOperasi(v)" data-toggle="modal" class="btn btn-icon alpha-blue border-blue" data-popup="tooltip" title="" data-original-title="Lihat Detail">
                      <i class="icon-file-eye"></i>
                    </a>
                  </td>
                </tr>
                <tr v-if="!(dataOperasi||[]).length">
                  <td colspan="99" class="text-center">Tidak ada Data</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal v-model="isOpenModalOperasi" :title="'Detail Operasi'" size="xl" ok-only ok-title="Tutup">
      <Operasi :dataOperasi="operasiData" :operasiId="idOperasi"/>
    </b-modal>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  import Operasi from '@/components/Ranap/Operasi.vue'

  export default {
    extends: GlobalVue,
    data() {
      return {
        rowReg: {},
        dataOperasi: [],
        
        patientData: {},
        loading: {
            patientInfo: false,
        },

        isOpenModalOperasi: false,
        operasiData: {},
        idOperasi: null,

      }
    },
    components:{
      PatientInfo,Operasi
    },
    methods: {

      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })

        // master
        if (this.$route.params.rmNo) {
          Gen.apiRest(
            "/get/" + url + '?master=1', {
              params: Object.assign({
                page: page
              }, paramsQuery, params.query || {})
            }
          ).then(res => {
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })
          })
        }

      },

      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
      },

      openFormOperasi(v){
        this.loadingOverlay = true
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              type: "get-detail-operasi",
              id_operasi: v.arano_id,
              id_pasien: this.row.ap_id
            }
          },
          "POST"
        ).then(res => {
          let resp = res.data
          if(!resp.isFound){
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Ditemukan'
            })
          }
          this.loadingOverlay = false
          this.isOpenModalOperasi = true
          this.idOperasi = v.arano_id
          this.operasiData = resp.dataOperasi
        })
      }
    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    }
  }

</script>